<template>
    <div
        class="dF fC f1 px-4 pb-4 hide-scrollbar"
        style="background: var(--light-bg-gray); overflow-y: auto"
        v-if="broadcasts.length && emailType != 'Templates'"
    >
        <BHLoading :show="$store.state.loading" />
        <a-modal
            title="Preview Email"
            v-model="preview.show"
            :footer="null"
            :width="700"
        >
            <iframe class="email-quick-preview" />
        </a-modal>

        <div class="f1 bg-white rounded" :key="emailType">
            <template v-if="emailType === 'Drafts'">
                <a-table
                    class="tableStyle"
					:rowKey="(e) => e.id"
                    :row-selection="rowSelection"
                    :columns="columnsForFilter"
                    :data-source="broadcasts"
					:loading="loading"
					:pagination="{
	                    current: currentPage,
	                    total: totalBroadcasts,
	                    pageSize: pageSize,
	                }"
	                @change="handleChange"
                >
                    <div slot="name" slot-scope="record" class="dF fC">
                        {{ record.name }}
                        <span style="color: var(--med-gray)">
                            Subject: {{ record.subject }}
                        </span>
                    </div>
                    <div slot="updatedAt" slot-scope="record">
                        <div style="color: #9ea0a5">
                            {{ formatDate(record.updatedAt) }}
                        </div>
                    </div>
                    <div slot="actions" slot-scope="record">
                        <!-- <router-link :to="`/draft/${record.id}`">
                            <a-icon type="edit" class="icon-link" />
                        </router-link> -->
						<MoreActions
                            style="margin-right: 1.33rem"
                            :items="tableActions"
                            :val="record"
                            @action="tableActioned"
                        />
                    </div>
                </a-table>
            </template>
            <template v-else-if="emailType == 'Scheduled' || emailType == 'Sent'">
                <a-table
                    class="tableStyle"
					:rowKey="(e) => e.id"
                    :columns="columnsForFilter"
                    :data-source="broadcasts"
					:scroll="{ x: 1100 }"
					:loading="loading"
					:pagination="{
	                    current: currentPage,
	                    total: totalBroadcasts,
	                    pageSize: pageSize,
	                }"
	                @change="handleChange"
                >
                    <div slot="name" slot-scope="record" class="dF fC">
                        {{ record.name }}
                        <span style="color: var(--med-gray)">
                            Subject: {{ record.subject }}
							<br>
							<span v-if="record.initialBroadcast && $store.state.email.broadcasts[record.initialBroadcast]">Previous Broadcast: {{ $store.state.email.broadcasts[record.initialBroadcast].name }} </span>
                        </span>
                    </div>
                    <div slot="sentDate" slot-scope="record" class="dF">
                        {{ sentDate(record) }}
                    </div>
                    <div slot="scheduled" slot-scope="record" class="dF">
                        {{ record.scheduledString }}
                    </div>
                    <div slot="delivered" slot-scope="record" class="dF">
                        <span v-if="isNaN(record.counts.totalRecipients)"
                            >N/A</span
                        >
                        <span v-else-if="!record.counts.delivered"
                            >0% <small>(0)</small></span
                        >
                        <span v-else>
                            <span style="display: block">
                                {{
                                    parseInt(
                                        (record.counts.delivered /
                                            record.counts.totalRecipients) *
                                            100
                                    )
                                }}%
                            </span>
                            <small> ({{ record.counts.delivered }}) </small>
                        </span>
                    </div>
                    <div slot="opened" slot-scope="record" class="dF">
                        <span
                            v-if="
                                !record.counts.delivered ||
                                !record.counts.opened
                            "
                            >0% <small>(0)</small></span
                        >
                        <span v-else>
                            {{
                                parseInt(
                                    (record.counts.opened /
                                        record.counts.delivered) *
                                        100
                                )
                            }}% <small>({{ record.counts.opened }})</small>
                        </span>
                    </div>
                    <div slot="clicks" slot-scope="record" class="dF">
                        <span
                            v-if="
                                !record.counts.delivered ||
                                !record.counts.clicked
                            "
                            >0% <small>(0)</small></span
                        >
                        <span v-else>
                            {{
                                parseInt(
                                    (record.counts.clicked /
                                        record.counts.delivered) *
                                        100
                                )
                            }}% <small>({{ record.counts.clicked }})</small>
                        </span>
                    </div>
                    <div slot="createdAt" slot-scope="record" class="dF">
                        <span>{{ formatDate(record.createdAt) }}</span>
                    </div>
                    <div slot="updatedAt" slot-scope="record" class="dF">
                        <span>{{ formatDate(record.updatedAt) }}</span>
                    </div>
                    <div slot="actions" slot-scope="record">
                        <MoreActions
                            style="margin-right: 1.33rem"
                            :items="tableActions"
                            :val="record"
                            @action="tableActioned"
                        />
                    </div>
                </a-table>
            </template>
            <template v-else-if="emailType == 'Processing'">
                <a-table
                    class="tableStyle"
					:rowKey="(e) => e.id"
                    :columns="progressColumns"
                    :data-source="broadcasts"
					:scroll="{ x: 1000 }"
					:loading="loading"
					:pagination="{
	                    current: currentPage,
	                    total: totalBroadcasts,
	                    pageSize: pageSize,
	                }"
	                @change="handleChange"
                >
                    <div slot="status" slot-scope="record" class="dF fC">
                        <a-badge
                            :color="record.processing ? 'cyan' : 'red'"
                            :text="record.processing ? 'Processing' : 'Errored'"
                        />
                    </div>
                    <div slot="name" slot-scope="record" class="dF fC">
                        {{ record.name }}
                        <span style="color: var(--med-gray)">
                            Subject: {{ record.subject }}
                        </span>
                    </div>
                    <div slot="progress" slot-scope="record" class="dF">
                        <a-progress
                            :percent="progressObj[record.id]"
                            size="small"
                            v-if="progressObj[record.id]"
                        />
                        <a-icon v-else-if="!record.error" type="loading" />
                        <span v-else>⚠️</span>
                    </div>
                    <div slot="sentDate" slot-scope="record" class="dF">
                        {{ sentDate(record) }}
                    </div>
                    <div slot="delivered" slot-scope="record" class="dF">
                        <span v-if="isNaN(record.counts.totalRecipients)"
                            >N/A</span
                        >
                        <span v-else-if="!record.counts.delivered"
                            >0% <small>(0)</small></span
                        >
                        <span v-else>
                            <span style="display: block">
                                {{
                                    parseInt(
                                        (record.counts.delivered /
                                            record.counts.totalRecipients) *
                                            100
                                    )
                                }}%
                            </span>
                            <small> ({{ record.counts.delivered }}) </small>
                        </span>
                    </div>
                    <div slot="opened" slot-scope="record" class="dF">
                        <span
                            v-if="
                                !record.counts.delivered ||
                                !record.counts.opened
                            "
                            >0% <small>(0)</small></span
                        >
                        <span v-else>
                            {{
                                parseInt(
                                    (record.counts.opened /
                                        record.counts.delivered) *
                                        100
                                )
                            }}% <small>({{ record.counts.opened }})</small>
                        </span>
                    </div>
                    <div slot="clicks" slot-scope="record" class="dF">
                        <span
                            v-if="
                                !record.counts.delivered ||
                                !record.counts.clicked
                            "
                            >0% <small>(0)</small></span
                        >
                        <span v-else>
                            {{
                                parseInt(
                                    (record.counts.clicked /
                                        record.counts.delivered) *
                                        100
                                )
                            }}% <small>({{ record.counts.clicked }})</small>
                        </span>
                    </div>
                    <div slot="createdAt" slot-scope="record" class="dF">
                        <span>{{ formatDate(record.createdAt) }}</span>
                    </div>
                    <div slot="updatedAt" slot-scope="record" class="dF">
                        <span>{{ formatDate(record.updatedAt) }}</span>
                    </div>
                    <div slot="actions" slot-scope="record">
                        <a-button
                            v-if="true"
                            icon="eye"
                            @click="previewEmail(record.prodHtml)"
                        />
                        <MoreActions
                            v-else
                            style="margin-right: 1.33rem"
                            :items="tableActions"
                            :val="record"
                            @action="tableActioned"
                        />
                    </div>
                </a-table>
            </template>
        </div>
    </div>

    <div
        class="dF fC f1 px-4 pb-4 hide-scrollbar"
        style="background: var(--light-bg-gray); overflow-y: auto"
        v-else-if="predefinedTemplates.length && emailType == 'Templates'"
    >
		<a-modal
			v-model="exportModal.visible"
			width="500px"
			:footer="null"
			@cancel="closeExportModal"
			:centered="true"
		>
			<BHLoading :show="loading" />
			<div class="mb-2">
				<h5 class="mb-4" v-text="'Export Template to Existing Project'" />

				<p class="mb-2" style="color: #40454c; font-size: 14px">
					Select a Project<span style="color: var(--danger)"> *</span>
				</p>
				<a-select
					size="large"
					v-model="exportModal.instance"
					class="w-full"
					placeholder="Select a Project"
					show-search :filter-option="filterOption"
				>
					<a-select-option v-for="(inst,instI) in instances" :value="inst.id" :key="inst+instI">{{inst.name}}</a-select-option>
				</a-select>
			</div>
			<div class="dF jE mt-5">
				<a-button class="cancel-button" @click="closeExportModal">CANCEL</a-button>
				<a-button class="ml-3" type="primary" @click="exportTemplate(exportModal.template)"
					>EXPORT</a-button
				>
			</div>
		</a-modal>
        <a-modal
            title="Preview Email"
            v-model="preview.show"
            :footer="null"
            :width="700"
        >
            <iframe class="email-quick-preview" />
        </a-modal>
        <div class="f1 bg-white rounded" :key="emailType">
            <template>
                <a-table
                    class="tableStyle"
					:rowKey="(e) => e.id"
                    :row-selection="rowSelection"
                    :columns="columnsForFilter"
                    :data-source="predefinedTemplates"
                >
                    <div slot="name" slot-scope="record" class="dF fC">
                        {{ record.name }}
                    </div>
                    <div slot="updatedAt" slot-scope="record">
                        <div style="color: #9ea0a5">
                            {{ formatDate(record.updatedAt) }}
                        </div>
                    </div>
                    <div slot="actions" slot-scope="record">
                        <MoreActions
                            style="margin-right: 1.33rem"
                            :items="templateActions(record)"
                        />
                    </div>
                </a-table>
            </template>
        </div>
    </div>

    <div
        class="dF fC f1 px-4 pb-4"
        style="background: var(--light-bg-gray)"
        v-else-if="!broadcasts.length && emailType != 'Templates'"
    >
        <EmptyApp
            :img="require('@/assets/no-email.svg')"
            :text="
                emailType.includes('Sent')
                    ? `You have not sent any broadcast yet`
                    : `You don't have any ${emailType}`
            "
        />
    </div>
    <div
        class="dF fC f1 px-4 pb-4"
        style="background: var(--light-bg-gray)"
        v-else-if="!predefinedTemplates.length && emailType == 'Templates'"
    >
        <EmptyApp
            :img="require('@/assets/no-email.svg')"
            :text="`You don't have any ${emailType}`"
        />
    </div>
    <div
        class="dF fC f1 px-4 pb-4"
        style="background: var(--light-bg-gray)"
        v-else
    >
        <EmptyApp
            :img="require('@/assets/no-email.svg')"
            :text="`You don't have any ${emailType}`"
        />
    </div>
</template>

<script>
import { getCookie, arrToObj, formatDate, setProp } from "bh-mod";
import axios from "axios";
import EmptyApp from "bh-mod/components/common/EmptyApp";
import MoreActions from "bh-mod/components/common/MoreActions";
import BHLoading from "bh-mod/components/common/Loading";
import moment from "moment";

export default {
    components: {
        EmptyApp,
        MoreActions,
        BHLoading,
    },
    data() {
        return {
			loading:false,
			exportModal:{
				visible:false,
				template:{},
				instance:''
			},
            progressObj: {},
            preview: {
                show: false,
                broadcast: "",
            },
            duplicate: {
                name: "",
                id: "",
                show: false,
                load: false,
            },
            filter: "all",
            completedActions: [
                {
                    icon: "eye",
                    label: "View Email",
                    value: "preview",
                },
				{
                    icon: "retweet",
                    label: "Resend Email",
                    value: "resend",
                },
                {
                    icon: "fe-activity",
                    label: "View Reports",
                    value: "viewReport",
                },
                {
                    icon: "fe-download",
                    label: "Download Report (CSV)",
                    value: "dlReport",
					info: "Overview of Data"
                },
                {
                    icon: "fe-download",
                    label: "Download Click Report (CSV)",
                    value: "dlClickReport",
					info: "Activity of Each User"
                },
                {
                    icon: "fe-copy",
                    label: "Copy Broadcast",
                    value: "duplicate",
                },
				{
                    icon: "fe-copy",
                    label: "Convert to Template",
                    value: "template",
                },
            ],
            scheduledActions: [
				{
					icon: "fe-edit",
                    label: "Edit",
                    value: "edit",
                },
                {
                    label: "Send Now",
                    icon: "fe-mail",
                    value: "sendNow",
                },
                {
                    label: "Preview Email",
                    icon: "eye",
                    value: "preview",
                },
                {
                    label: "Delete",
                    icon: "fe-trash-2",
                    value: "delete",
                },
            ],
            progressActions: [
                {
                    label: "Preview Email",
                    value: "preview",
                },
                {
                    label: "Stop Broadcast",
                    value: "stop",
                },
            ],
            draftActions: [
                {
					icon: "fe-edit",
                    label: "Edit",
                    value: "edit",
                },
				{
                    icon: "fe-copy",
                    label: "Convert to Template",
                    value: "template",
                },
				{
					icon: "delete",
					label: "Delete",
					value: "delete"
				}
            ],
            selectedRowKeys: [],
            completedColumns: [
                {
                    title: "Name",
                    scopedSlots: { customRender: "name" },
                    key: "name",
                    sorter: (a, b) =>
                        a.name < b.name ? -1 : a.name > b.name ? 1 : 0,
					width: "30%",
                },
                {
                    title: "Sent",
                    scopedSlots: { customRender: "sentDate" },
                    key: "sentDate",
                    sorter: (a, b) => {
						if (!a.sentDate) a.sentDate = '0';
						if (!b.sentDate) b.sentDate = '0';
						const aDate =
                            +a.sentDate > a.scheduled
                                ? +a.sentDate
                                : a.scheduled;
                        const bDate =
                            +b.sentDate > b.scheduled
                                ? +b.sentDate
                                : b.scheduled;
                        return aDate - bDate;
                    },
                    defaultSortOrder: "descend",
                },
                {
                    title: "Delivered",
                    scopedSlots: { customRender: "delivered" },
                    key: "delivered",
                    sorter: (a, b) =>
                        ((a.counts && a.counts.delivered) || 0) -
                        ((b.counts && b.counts.delivered) || 0),
                },
                {
                    title: "Opened",
                    scopedSlots: { customRender: "opened" },
                    key: "opened",
                    sorter: (a, b) =>
                        ((a.counts && a.counts.opened) || 0) -
                        ((b.counts && b.counts.opened) || 0),
                },
                {
                    title: "Clicks",
                    scopedSlots: { customRender: "clicks" },
                    key: "clicks",
                    sorter: (a, b) =>
                        ((a.counts && a.counts.clicked) || 0) -
                        ((b.counts && b.counts.clicked) || 0),
                },
                {
                    title: "Date Created",
                    scopedSlots: { customRender: "createdAt" },
                    key: "createdAt",
                    sorter: (a, b) =>
                        moment(a.createdAt).format("x") -
                        moment(b.createdAt).format("x"),
                },
                {
                    title: "Last Updated",
                    scopedSlots: { customRender: "updatedAt" },
                    key: "updatedAt",
                    sorter: (a, b) =>
                        moment(a.updatedAt).format("x") -
                        moment(b.updatedAt).format("x"),
                },
                {
                    title: "",
                    scopedSlots: { customRender: "actions" },
                    key: "actions",
                    width: "5%",
                },
            ],
            draftColumns: [
                {
                    title: "Name",
                    scopedSlots: { customRender: "name" },
                    key: "name",
                    sorter: (a, b) =>
                        a.name < b.name ? -1 : a.name > b.name ? 1 : 0,
                },
                {
                    title: "Last Updated",
                    scopedSlots: { customRender: "updatedAt" },
                    key: "updatedAt",
					defaultSortOrder: "descend",
                    sorter: (a, b) =>
                        moment(a.updatedAt).format("x") -
                        moment(b.updatedAt).format("x"),
                },
                {
                    title: "",
                    scopedSlots: { customRender: "actions" },
                    key: "actions",
                    width: "5%",
                },
            ],
            scheduledColumns: [
                {
                    title: "Name",
                    scopedSlots: { customRender: "name" },
                    key: "name",
                    sorter: (a, b) =>
                        a.name < b.name ? -1 : a.name > b.name ? 1 : 0,
                },
                {
                    title: "Scheduled",
                    scopedSlots: { customRender: "scheduled" },
                    key: "scheduled",
					defaultSortOrder: "descend",
                    sorter: (a, b) =>
                        moment(a.ScheduledString).format("x") -
                        moment(b.ScheduledString).format("x"),
                },
                {
                    title: "",
                    scopedSlots: { customRender: "actions" },
                    key: "actions",
                    width: "5%",
                },
            ],
			resizeObserver: null,
			sortedInfo: {
				key: "sentDate",
				order: "DESC",
			},
			currentPage: 1,
            totalBroadcasts: 0,
            pageSize: 10,
			search: ''
        };
    },
    watch: {
        selectedRowKeys(val) {
            let pageButtons = [];
            if (this.emailType === "Templates") {
                pageButtons = [
                    {
                        label: "CREATE NEW TEMPLATE",
                        ant: "plus",
                        callback: this.newTemplate,
                    },
                ];
            } else {
                pageButtons = [
                    {
                        label: "CREATE BROADCAST",
                        ant: "plus",
                        callback: this.newBroadcast,
                    },
                ];
            }
            if (val.length && this.emailType === "Drafts") {
                pageButtons = [
                    {
                        label: "DELETE SELECTED",
                        ant: "delete",
                        type: "danger",
                        callback: this.deleteSelected,
                    },
                    {
                        label: "CREATE BROADCAST",
                        ant: "plus",
                        callback: this.newBroadcast,
                    },
                ];
            } else if (val.length && this.emailType === "Templates") {
                pageButtons = [
                    {
                        label: "DELETE SELECTED",
                        ant: "delete",
                        type: "danger",
                        callback: this.deleteSelected,
                    },
                    {
                        label: "CREATE NEW TEMPLATE",
                        ant: "plus",
                        callback: this.newTemplate,
                    },
                ];
            }
            this.$store.commit("PAGE_BUTTONS", pageButtons);
        },
        emailType: {
            immediate: true,
            async handler(val) {
				this.currentPage = 1;
				this.totalBroadcasts = 0;
				if(val === 'Templates') {
					this.$store.commit("PAGE_BUTTONS", [
						{
							label: "CREATE NEW TEMPLATE",
							ant: "plus",
							callback: this.newTemplate,
						},
					]);
					this.$forceUpdate();
					return;
				}
				this.$store.commit("LOAD");
				await this.getBroadcasts()
				this.$nextTick(() => {
					this.updateScrollVisibility();
				})
                this.$store.commit("LOAD", false);
                this.selectedRowKeys = [];
                this.stopProgress();
                if (val === "Processing" && !this.watchingProgress) {
                    this.progressInterval = setInterval(() => {
                        this.getProgress();
                    }, 5000);
                }

                let pageButtons = [
                    {
                        label: "CREATE BROADCAST",
                        ant: "plus",
                        callback: this.newBroadcast,
                    },
                ];
                if (val === "Templates") {
                    pageButtons = [
                        {
                            label: "CREATE NEW TEMPLATE",
                            ant: "plus",
                            callback: this.newTemplate,
                        },
                    ];
                }
                this.$store.commit("PAGE_BUTTONS", pageButtons);
            },
        },
    },
    computed: {
		instances(){
			return this.$store.state.instances.filter(x => x.id != this.instance.id).sort((a,b) => {
				let nameA = a.name.toUpperCase()
				let nameB = b.name.toUpperCase()
				if (nameA < nameB) return -1
				if (nameA > nameB) return 1
				return 0
			})
		},
        progressColumns() {
            return [
                {
                    title: "Status",
                    scopedSlots: { customRender: "status" },
                    key: "status",
                    sorter: (a, b) => a.processing - b.processing,
                },
                {
                    title: "Name",
                    scopedSlots: { customRender: "name" },
                    key: "name",
                    sorter: (a, b) =>
                        a.name < b.name ? -1 : a.name > b.name ? 1 : 0,
                },
                {
                    title: "Progress",
                    scopedSlots: { customRender: "progress" },
                    key: "progress",
                    sorter: (a, b) =>
                        this.progressObj[a.id] ||
                        0 - this.progressObj[b.id] ||
                        0,
                },
                {
                    title: "Last Updated",
                    scopedSlots: { customRender: "updatedAt" },
                    key: "updatedAt",
                    sorter: (a, b) =>
                        moment(a.updatedAt).format("x") -
                        moment(b.updatedAt).format("x"),
                },
                {
                    title: "",
                    scopedSlots: { customRender: "actions" },
                    key: "actions",
                    width: "5%",
                },
            ];
        },
        siteURL() {
            return this.$store.state.email.siteURL;
        },
        tableActions() {
            if (this.emailType === "Sent") return this.completedActions;
            else if (this.emailType === "Drafts") return this.draftActions;
            else if (this.emailType === "Processing")
                return this.progressActions;
            return this.scheduledActions;
        },
        columnsForFilter() {
            if (this.emailType === "Sent") return this.completedColumns;
            else if (
                this.emailType === "Drafts" ||
                this.emailType === "Templates"
            )
                return this.draftColumns;
            else if (this.emailType === "Processing")
                return this.processColumns;
            return this.scheduledColumns;
        },
        emailType() {
            let { filter } = this.$store.state.email;
            return filter[0].toUpperCase() + filter.substr(1);
        },
        broadcasts() {
            if (this.emailType === "Sent") return this.$store.getters.completed;
            else if (this.emailType === "Drafts")
                return this.$store.getters.drafts;
            else if (this.emailType === "Templates")
                return this.$store.getters.templates;
            else if (this.emailType === "Processing")
                return this.$store.getters.processing;
            else return this.$store.getters.scheduled;
        },
        templates() {
            let newArray = [];
            let templates = Object.values(this.$store.state.email.templates);

            let blank = templates.find((x) => x.name.includes("Blank T"));
            if (blank)
                newArray.push(blank),
                    (templates = templates.filter(
                        (x) => !x.name.includes("Blank T")
                    ));
            newArray = [...newArray, ...templates];

            return arrToObj(newArray);
        },
        predefinedTemplates() {
            let newArr = [];
            let templates = Object.values(this.$store.state.email.templates);
            templates.forEach((x) => {
                x.key = x.id;
                if (x.default && x.instance) {
                    newArr.push(x);
                }
            });
            return newArr;
        },
        draftArray() {
            let drafts = this.drafts;
            let arr = [];
            for (var i in drafts) {
                arr.push(drafts[i]);
            }
            return arr;
        },
        drafts() {
            return this.$store.state.email.drafts;
        },
        rowSelection() {
            const { selectedRowKeys } = this;
            return {
                selectedRowKeys,
                onChange: this.onSelectChange,
                hideDefaultSelections: false,
                onSelection: this.onSelection,
            };
        },
        instance() {
            return this.$store.state.instance;
        },
        senders() {
            let senders = this.$store.state.email.senders;
            let sender = {};
            for (var i in senders) {
                if (senders[i].active) {
                    sender = JSON.parse(JSON.stringify(senders[i]));
                    break;
                }
            }
            return sender;
        },

        domain() {
            return this.$store.state.email.domain;
        },

		emailTypeParams() {
			if (this.emailType === "Sent") return 'sent=true';
            else if (this.emailType === "Drafts") return 'sent=false&scheduled=0&processing=false';
            else if (this.emailType === "Processing") return '_where[_or][0][processing]=true&_where[_or][1][error]=true&sent=false';
            else if (this.emailType === "Scheduled") return 'sent=false&isScheduled=true';
			return '';
		}
    },
    methods: {
		closeExportModal(){
			this.exportModal = {
				visible:false,
				template:{},
				instance:''
			}
		},
        deleteSelected() {
            if (this.$p < 40)
                return this.$message.error("You do not have access to delete.");
            this.$confirm({
                title: "Are you sure you want to delete the selected items?",
                okText: "Yes",
                cancelText: "No",
                onOk: () => {
                    let promises = [];
                    if (!this.selectedRowKeys.length)
                        return this.$message({
                            message: "No items selected",
                            type: "warning",
                        });
                    if (this.emailType === "Drafts") {
                        this.$store.commit("LOAD", true);
                        this.selectedRowKeys.forEach((x) => {
                            promises.push(
                                this.$api.delete(`/broadcasts/:instance/${x}`)
                            );
                        });
                        Promise.all(promises)
                            .then(() => {
                                this.selectedRowKeys.forEach((id) => {
                                    this.$store.commit("SET_PROP", {
                                        where: ["broadcasts", id],
                                        del: true,
                                    });
                                });
                            })
                            .catch((err) => {
                                console.error("ERROR deleting", err);
                            })
                            .finally(() => {
                                this.selectedRowKeys = [];
                                this.$store.commit("LOAD", false);
                            });
                    } else if (this.emailType === "Templates") {
                        this.$store.commit("LOAD", true);
                        this.selectedRowKeys.forEach((x) => {
                            promises.push(
                                this.$api.delete(`/projects/:instance/${x}`)
                            );
                        });
                        Promise.all(promises)
                            .then(() => {
                                this.selectedRowKeys.forEach((id) => {
                                    this.$store.commit("SET_PROP", {
                                        where: ["templates", id],
                                        del: true,
                                    });
                                });
                            })
                            .catch((err) => {
                                console.error("ERROR deleting", err);
                            })
                            .finally(() => {
                                this.selectedRowKeys = [];
                                this.$store.commit("LOAD", false);
                            });
                    }
                },
            });
        },
        stopProgress() {
            if (this.progressInterval) {
                clearInterval(this.progressInterval);
                this.watchingProgress = 0;
            }
        },
        getProgress() {
            this.watchingProgress = 1;
            for (const key in this.broadcasts) {
                if (Object.hasOwnProperty.call(this.broadcasts, key)) {
                    const br = this.broadcasts[key];
                    if (br.processing && !br.error) {
                        this.$api
                            .get(`/broadcasts/:instance/${br.id}/progress`)
                            .then(({ data }) => {
                                if (data.addedCount || data.totalContacts) {
                                    let result = parseInt(
                                        (data.addedCount / data.totalContacts) *
                                            100
                                    );
                                    if (result === 100) {
                                        this.$store.commit("SET_PROP", {
                                            where: [
                                                "broadcasts",
                                                br.id,
                                                "processing",
                                            ],
                                            what: false,
                                        });
                                        this.$store.commit("SET_PROP", {
                                            where: [
                                                "broadcasts",
                                                br.id,
                                                "error",
                                            ],
                                            what: false,
                                        });
                                        this.$store.commit("SET_PROP", {
                                            where: [
                                                "broadcasts",
                                                br.id,
                                                "sent",
                                            ],
                                            what: true,
                                        });
                                    }
                                    setProp(this.progressObj, [br.id], result);
                                } else {
                                    throw "asdf";
                                }
                            })
                            .catch(() => {
                                setProp(this.progressObj, [br.id], -1);
                            });
                    }
                }
            }
        },
        async previewProject(project) {
            this.$store.commit("LOAD", true);
            let { data } = await this.$api.get(
                `/projects/:instance/${project.id}`
            ).catch(err => {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			});
            let { data: html } = await axios.post(
                `${this.siteURL}/email?test=1&fname=Firstname&lname=Lastname&email=name@website.com`,
                data
            );
            this.$store.commit("LOAD", false);
			console.log('HTML', html)
            this.previewEmail(html);
        },
        async copyTemplate(template) {
            template = JSON.parse(JSON.stringify(template));

            this.$store.commit("LOAD", true);
            let id = template.id;
            delete template.id;
            delete template._id;
            let { data } = await this.$api.get(`/projects/:instance/${id}`).catch(err => {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			});
            let { id: id2, _id, pages = [], ...finalObj } = data;

            if (!pages || !pages.length) {
                this.$message.error(
                    "There is a problem with this Template, Please choose another!"
                );
                return this.$store.commit("LOAD", false);
            }

            let page = pages[0];

            delete page._id;
            delete page.id;

            delete page.header.id;
            delete page.header._id;
            if (page.footer) {
                delete page.footer.id;
                delete page.footer._id;
            }

            let { sections = [] } = page;
            sections = sections.map(({ _id, id, ...sec }) => sec);

            page.sections = sections;

            finalObj.pages = [page];
            finalObj.name += " Copy";
            this.$api
                .post(`/projects/:instance/`, finalObj)
                .then(async ({ data }) => {
                    this.$message.success("Succesfully Duplicated");
                    this.$store.commit("SET_PROP", {
                        where: ["templates", data.id],
                        what: data,
                    });
                    this.$router.push(`/template/${data.id}`);
                })
                .catch(() => {
                    this.$message.error("There was an error saving your file");
                })
                .finally(() => {
                    this.$store.commit("LOAD", false);
                });
        },
		async exportTemplate(template) {
			if (this.exportModal.instance == ''){
				return this.$message.error('Please select an instance')
			}
            template = JSON.parse(JSON.stringify(template));

            this.loading = true;
            let id = template.id;
            delete template.id;
            delete template._id;
            let { data } = await this.$api.get(`/projects/:instance/${id}`).catch(err => {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			});
            let { id: id2, _id, pages = [], ...finalObj } = data;

            if (!pages || !pages.length) {
                this.$message.error(
                    "There is a problem with this Template, Please choose another!"
                );
                return this.loading = false
            }

            let page = pages[0];

            delete page._id;
            delete page.id;

            delete page.header.id;
            delete page.header._id;
            if (page.footer) {
                delete page.footer.id;
                delete page.footer._id;
            }

            let { sections = [] } = page;
            sections = sections.map(({ _id, id, ...sec }) => sec);

            page.sections = sections;

            finalObj.pages = [page];
            finalObj.name += " Copy";
            this.$api
                .post(`/projects/${this.exportModal.instance}/`, finalObj)
                .then(async ({ data }) => {
                    this.$message.success("Succesfully Duplicated");
					this.closeExportModal()
					this.loading = false;
                })
                .catch(() => {
                    this.$message.error("There was an error saving your file");
					this.loading = false;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
		async convertTemplate(template) {

            this.$store.commit("LOAD", true);
            let { data } = await this.$api.get(`/projects/:instance/${template}`).catch(err => {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			});
            let { id: id2, _id, pages = [], ...finalObj } = data;

            if (!pages || !pages.length) {
                this.$message.error(
                    "There is a problem with this Template, Please choose another!"
                );
                return this.$store.commit("LOAD", false);
            }

            let page = pages[0];

            delete page._id;
            delete page.id;

            delete page.header.id;
            delete page.header._id;
            if (page.footer) {
                delete page.footer.id;
                delete page.footer._id;
            }

            let { sections = [] } = page;
            sections = sections.map(({ _id, id, ...sec }) => sec);

            page.sections = sections;

            finalObj.pages = [page];
            finalObj.name += " Copy";
			finalObj.default = true;
            this.$api
                .post(`/projects/:instance/`, finalObj)
                .then(async ({ data }) => {
                    this.$message.success("Succesfully Duplicated");
                    this.$store.commit("SET_PROP", {
                        where: ["templates", data.id],
                        what: data,
                    });
                    this.$router.push(`/template/${data.id}`);
                })
                .catch(() => {
                    this.$message.error("There was an error saving your file");
                })
                .finally(() => {
                    this.$store.commit("LOAD", false);
                });
        },
        templateActions(template) {
            return [
                {
                    icon: "fe-edit",
                    label: "Edit Template",
                    value: "template",
                    action: () => {
                        this.$router.push(`/template/${template.id}`);
                    },
                },
                {
                    icon: "fe-copy",
                    label: "Copy Template",
                    value: "copytemplate",
                    action: () => this.copyTemplate(template),
                },
                {
                    icon: "fe-eye",
                    label: "Preview Template",
                    value: "preview",
                    action: () => this.previewProject(template),
                },
				{
                    icon: "fe-upload",
                    label: "Export to Project",
                    value: "export",
					action: () => {
						this.exportModal = {
							visible: true,
							template: template,
							instance: '',
						}
					}
                },
                {
                    icon: "fe-trash-2",
                    label: "Delete",
                    value: "delete",
                    action: () => {
                        if (this.$p < 40)
                            return this.$message.error(
                                "You do not have permission to delete this template"
                            );
                        this.$confirm({
                            title: "Delete Template",
                            content: (h) => (
                                <div>
                                    Are you sure you want to delete this
                                    template <b>({template.name})</b>?
                                </div>
                            ),
                            okText: "Yes",
                            cancelText: "No",
                            onOk: () => {
                                this.$store.commit("LOAD", true);
                                this.$api
                                    .delete(
                                        `/projects/:instance/${template.id}`
                                    )
                                    .then(({ data }) => {
                                        this.$store.commit("SET_PROP", {
                                            where: ["templates", template.id],
                                            del: true,
                                        });
                                        this.$message.success(
                                            "Template Deleted!"
                                        );
                                    })
									.catch(err => {
										if (!err || !err.response || !err.response.status || err.response.status !== 400) {
											this.$message.error(this.$err(err))
										}
									})
                                    .finally(() => {
                                        this.$store.commit("LOAD", false);
                                    });
                            },
                        });
                    },
                },
            ];
        },
		previewEmail(html) {
			this.preview = {
				show: true,
			};
			setTimeout(() => {
				var doc = document.querySelector("iframe.email-quick-preview")?.contentWindow?.document;
				if (doc) {
					doc.open();
					doc.write(html);
					doc.close();
				}
			}, 10);
		},
        tableActioned(e) {
            let self = this;
            let { key, val } = e;
            if (key === "preview") {
                this.previewEmail(val.prodHtml);
            } else if (key === "resend"){
				this.$router.push(`/resend/${val.id}`)
			} else if (key === "edit"){
				if (this.emailType === 'Scheduled') {
					if (val.scheduled) {
						this.$router.push(`/scheduled/${val.id}`)
					} else {
						return this.$message.error('Edit is not allowed for older scheduled broadcast.')
					}
				} else {
					this.$router.push(`/draft/${val.id}`)
				}
			} else if (key === "sendNow") {
                let self = this;
                this.$confirm({
                    title: "Send now?",
                    content: (h) => (
                        <div>
                            Are you sure you want to remove scheduling and send
                            this broadcast now?
                        </div>
                    ),
                    okText: "Yes, Send now",
                    okType: "danger",
                    cancelText: "Cancel",
                    centered: true,
                    onOk() {
                        if (self.$p < 40)
                            return self.$message.error(
                                "You do not have permission to send this broadcast"
                            );
                        self.$store.commit("LOAD", true);
                        self.$api
                            .put(`/broadcasts/:instance/${val.id}/sendnow`)
                            .then(({ data }) => {
                                self.$store.commit("SET_PROP", {
                                    where: [
                                        "broadcasts",
                                        val.id,
                                        "isScheduled",
                                    ],
                                    what: false,
                                });
                                self.$store.commit("SET_PROP", {
                                    where: ["broadcasts", val.id, "processing"],
                                    what: true,
                                });
                                self.$message.success("Broadcast Sending now!");
                            })
                            .catch(() => {
                                self.$message.error(
                                    "There was an error sending your broadcast"
                                );
                            })
                            .finally(() => {
                                self.$store.commit("LOAD", false);
                            });
                    },
                    onCancel() {
                        console.log("Cancel");
                    },
                });
            } else if (key === "viewReport") {
                window.open(
                    `https://reports.bildhive.${this.$tld}/email/${val.id}`,
                    "_blank"
                );
            } else if (key === "dlReport") {
                fetch(
                    `https://hook.bildhive.${this.$tld}/events/${val.id}/csv`,
                    {
                        headers: {
                            "Content-Type": "text/csv",
                            Authorization: `Bearer ${getCookie("__bhjt")}`,
                            "x-instance": getCookie("instance"),
							"x-subdomain": "email"
                        },
                        responseType: "blob",
                    }
                )
                    .then((response) => response.blob())
                    .then((blob) => {
                        var a = window.document.createElement("a");
                        a.href = window.URL.createObjectURL(blob, {
                            type: "text/csv",
                        });
                        a.download = val.id + ".csv";
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                    });
            } else if (key === "dlClickReport") {
                fetch(
                    `https://hook.bildhive.${this.$tld}/events/${val.id}/clickedcsv`,
                    {
                        headers: {
                            "Content-Type": "text/csv",
                            Authorization: `Bearer ${getCookie("__bhjt")}`,
                            "x-instance": getCookie("instance"),
							"x-subdomain": "email"
                        },
                        responseType: "blob",
                    }
                )
                    .then((response) => {
                        if (response.status === 400) {
                            return new Error(
                                "There was an error downloading your report"
                            );
                        } else {
                            return response.blob();
                        }
                    })

                    .then((blob) => {
                        var a = window.document.createElement("a");
                        a.href = window.URL.createObjectURL(blob, {
                            type: "text/csv",
                        });
                        a.download = val.id + "-clickThroughReport.csv";
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                    })
                    .catch(() => {
                        this.$message.error(
                            "No click events found for this Broadcast."
                        );
                    });
            } else if (key === "duplicate") {
                this.$store.commit("LOAD", true);
                this.$api
                    .put(`/broadcasts/:instance/${val.id}/clone`)
                    .then(({ data }) => {
                        this.$store.commit("SET_PROP", {
                            where: ["broadcasts", data.id],
                            what: data,
                        });
                        this.$router.push(`/draft/${data.id}`);
                    })
                    .catch(() => {
                        this.$message.error(
                            "Sorry there was an error duplicating your Broadcast. Please try again at a later time."
                        );
                    })
                    .finally(() => {
                        this.$store.commit("LOAD", false);
                    });
            } else if (key === "template"){
				console.log("Converting to template...", val.project)
				this.convertTemplate(val.project)
			} else if (key === "export"){
				console.log('Exporting to project...', val.id)
			} else if (key === "delete") {
                if (this.$p < 40)
                    return this.$message.error(
                        "You do not have permission to delete this broadcast"
                    );
                this.$confirm({
                    title: "Delete Scheduled Broadcast",
                    content: (h) => (
                        <div>
                            Are you sure you want to delete this broadcast ?
                        </div>
                    ),
                    okText: "Yes, Delete",
                    okType: "danger",
                    cancelText: "No",
                    onOk: () => {
                        this.$store.commit("LOAD", true);
                        this.$api
                            .delete(`/broadcasts/:instance/${val.id}`)
                            .then(({ data }) => {
                                this.$store.commit("SET_PROP", {
                                    where: ["broadcasts", val.id],
                                    del: true,
                                });
                                this.$message.success("Broadcast Deleted!");
                            })
                            .catch(() => {
                                this.$message.error(
                                    "There was an error deleting your broadcast"
                                );
                            })
                            .finally(() => {
                                this.$store.commit("LOAD", false);
                            });
                    },
                });
            }
        },
        sentDate(record) {
			if (!record.sentDate) return 'N/A'
            let sentDate = new Date(+record.sentDate).getTime();
            return sentDate > record.scheduled
                ? formatDate(sentDate)
                : formatDate(record.scheduled);
        },
        editBroadcast(draft) {
            this.$router.push(`/edit/${draft.id}`);
            this.$store.commit("EDIT_BROADCAST", draft);
            setTimeout(() => {
                this.$store.commit("EMAIL_TYPE", { value: "Edit Broadcast" });
            }, 1000);
        },
        copyBroadcast(draft) {
            let sendObj = JSON.parse(JSON.stringify(draft));
            sendObj.name = sendObj.name + " (copy)";

            this.$api
                .post(`/emails/${this.instance.id}/clone`, sendObj)
                .then(({ data }) => {
                    this.drafts[data.id] = data;
                    this.draftArray.push(data);
                    this.selectedRowKeys = [];
                }).catch(err => {
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err))
					}
				});
        },
        deleteBroadcast(draft) {
            if (this.$p < 40)
                return this.$message.error(
                    "You do not have permission to delete this broadcast"
                );
            let self = this;
            this.$confirm({
                title: "Delete Draft?",
                content: (h) => <div>Do you want to delete this draft?</div>,
                okText: "Delete",
                okType: "danger",
                cancelText: "Cancel",
                centered: true,
                onOk() {
                    self.confirmDeleteBroadcast(draft);
                    setTimeout(() => {
                        self.selectedRowKeys = [];
                    }, 1000);
                },
                onCancel() {
                    console.log("Cancel");
                },
            });
        },
        confirmDeleteBroadcast(draft) {
            this.$api
                .delete(`/emails/${this.instance.id}/${draft.id}`)
                .then(({ data }) => {
                    console.log("DATA THAT CAME BACK", data);
                    let i = this.draftArray.findIndex((x) => x.id == data.id);
                    delete this.drafts[data.id];
                    this.draftArray.splice(i, 1);
                }).catch(err => {
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err))
					}
				});
        },
        async bulkActioned({ key, keyPath, val }) {
            let self = this;
            if (key == "delete") {
                if (this.$p < 40)
                    return this.$message.error(
                        "You do not have permission to delete these broadcasts"
                    );
                this.$confirm({
                    title: "Delete Draft(s)?",
                    content: (h) => (
                        <div>Do you want to delete these draft(s)?</div>
                    ),
                    okText: "Delete",
                    okType: "danger",
                    cancelText: "Cancel",
                    centered: true,
                    onOk() {
                        for (var i = 0; i < self.selectedRowKeys.length; i++) {
                            let id = self.selectedRowKeys[i];
                            self.confirmDeleteBroadcast(self.draftArray[id]);
                        }
                        setTimeout(() => {
                            self.selectedRowKeys = [];
                        }, 1000);
                    },
                    onCancel() {
                        console.log("Cancel");
                    },
                });
            }
        },
        formatDate,
		filterOption(input, option) {
			return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
		},
        getDeliveredPerc({ counts }) {
            let { delivered = 0, totalRecipients = 0 } = counts;
            return (delivered / totalRecipients) * 100;
        },
        itemMenuClick({ item, key, keyPath }, broadcast) {
            if (key === "dup") {
                this.dupTemplate(broadcast.email);
            } else if (key === "report") {
                this.$message.error(
                    "This functionality is currently not available"
                );
            }
        },
        async handleDuplicate() {
            this.duplicate.load = true;

            let { id, name } = this.duplicate;
            let { data } = await this.$api.post(
                `/emails/${this.instance.id}/clone`,
                { id, name }
            ).catch(err => {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			});

            this.$store.commit("SHOW_EDIT_TEMPLATE", data);
            setTimeout(() => {
                this.duplicate.load = false;
                this.closeDuplicate();
            }, 1000);
        },
        dupTemplate({ id, name }) {
            this.duplicate = {
                show: true,
                id,
                name,
            };
        },
        closeDuplicate() {
            this.duplicate = {
                show: false,
                name: "",
                id: "",
            };
        },
        openTemplate(template) {
            this.$store.commit("SHOW_EDIT_TEMPLATE", template);
        },
        removeSearch({ srch, searchKey }) {
            this.$store.commit("REMOVE_SEARCH_PARAM", { srch, searchKey });
        },
        onSelectChange(selectedRowKeys) {
            this.selectedRowKeys = selectedRowKeys;
        },
        newBroadcast() {
            // if (!this.senders.active) {
            //     return this.$message.error(
            //         "Please verify your email account in settings before proceeding."
            //     );
            // }
            // if (!this.domain.name) {
            //     return this.$message.error(
            //         "Please add your domain details first in settings before proceeding."
            //     );
            // }
            // if (!this.domain.verified) {
            //     return this.$message.error(
            //         "Please verify your domain first in settings before proceeding."
            //     );
            // }
			// if (!this.domain.hasSSLCertificate) {
            //     return this.$message.error(
            //         "Please generate SSL certificate for your domain first in settings before proceeding."
            //     );
            // }
            this.$router.push("/new");
        },
        newTemplate() {
            this.$router.push("/template");
        },

		updateScrollVisibility() {
			let scrollContent = document.querySelector('div.ant-table-body');
			let scrollLeftButton = document.querySelector('.scroll-left')
			let scrollRightButton = document.querySelector('.scroll-right')

			if (scrollContent && scrollLeftButton && scrollRightButton) {
				if (scrollContent.offsetWidth < scrollContent.scrollWidth) {
					scrollLeftButton.classList.remove('hide');
					scrollRightButton.classList.remove('hide');
					scrollContent.classList.add('mx-3');
				} else {
					scrollLeftButton.classList.add('hide');
					scrollRightButton.classList.add('hide');
					scrollContent.classList.remove('mx-3');
				}
			}
		},
		async getBroadcasts() {
            this.loading = true;
            try {
                let start = (this.currentPage - 1) * this.pageSize;
                let searchQuery = "";
                if (this.search) {
                    searchQuery = `&name_contains=${this.search}`;
                }
                let { data } = await this.$api.get(
                    `/broadcasts/:instance/paginate?${this.emailTypeParams}&marketing_workflow_null=true&_start=${start}&_limit=${this.pageSize}&_sort=${this.sortedInfo.key}:${this.sortedInfo.order}${searchQuery}`
                );
				this.$store.commit("UPDATE_BROADCAST", data.items);
                this.totalBroadcasts = data.count || 0;
            } catch (err) {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err, `An error occurred while fetching broadcast list. Please try again.`))
				}
            }
            this.loading = false;
        },

		handleChange(pagination, filter, sorter) {
            if (pagination) {
                this.currentPage = pagination.current;
            }
            if (sorter && sorter.column) {
                this.sortedInfo = {
                    key: sorter.columnKey,
                    order: sorter.order === "descend" ? "DESC" : "ASC",
                };
            }
            this.getBroadcasts();
        },
    },
    mounted() {
        this.$store.commit("SET_EMAIL_FILTER");
		this.$api.get('/instances').then(({data}) => {
			data = data.filter(x => x.role && x.role.name === 'bh_admin').map(x => {
				return {
					id: x.instance.id,
					name: x.instance.name
				}
			})
			console.log('DATA COMING BACK FROM INSTANCES', data)
			this.$store.commit('SET_INSTANCES', data)
		}).catch(err => {
			if (!err || !err.response || !err.response.status || err.response.status !== 400) {
				this.$message.error(this.$err(err))
			}
		})

		this.$nextTick(() => {
			const scrollableArea = document.querySelector('div.ant-table-body')

			if (!scrollableArea) {
				return;
			}

			var scrollLeftButton = document.createElement('div');
			var scrollRightButton = document.createElement('div');

			scrollLeftButton.className = 'scroll-button scroll-left';
			scrollLeftButton.innerHTML = '&lt;';
			scrollableArea.appendChild(scrollLeftButton);

			scrollRightButton.className = 'scroll-button scroll-right';
			scrollRightButton.innerHTML = '&gt;';
			scrollableArea.appendChild(scrollRightButton);


			scrollLeftButton.addEventListener('click', function () {
				const scrollSize = Math.floor(scrollableArea.clientWidth - (scrollableArea.clientWidth * 0.1))
				scrollableArea.scrollBy({
					left: -scrollSize,
					behavior: "smooth",
				})
			});

			scrollRightButton.addEventListener('click', function () {
				const scrollSize = Math.floor(scrollableArea.clientWidth - (scrollableArea.clientWidth * 0.1))
				scrollableArea.scrollBy({
					left: scrollSize,
					behavior: "smooth",
				})
			});
		})

		let scrollableArea = document.querySelector('div.ant-table-body');
		this.resizeObserver = new ResizeObserver(() => {
			window.requestAnimationFrame(() => {
				this.updateScrollVisibility()
			})
		});
		if (scrollableArea) {
			this.resizeObserver.observe(scrollableArea);
		}
		window.addEventListener('resize', this.updateScrollVisibility)
    },
    beforeDestroy() {
        this.stopProgress();
		window.removeEventListener('resize', this.updateScrollVisibility)
		if (this.resizeObserver) {
			this.resizeObserver.disconnect();
		}
    },
    beforeUnmount() {
        this.stopProgress();
    },
    created() {
        if (location.href.includes("?code=")) {
            let { code } = this.$route.query;
            if (code === "1") {
                this.$message.success(
                    "Your email account has been verified. To make changes to sender information, please go to SETTINGS."
                );
            } else {
                this.$message.error(
                    "There was an error verifying your email account. Please try again."
                );
            }
            history.replaceState(null, null, "/");
        }
    },
};
</script>
<style lang="scss" module>
@import "./style.module.scss";
</style>
<style lang="scss" scoped>
.contacts-page {
    @media screen and (min-width: 567px) {
        max-width: calc(100% - 35px);
    }
}
.aB {
    align-items: baseline;
}

.row-mb {
    [class^="ant-col"] {
        margin-bottom: 20px;
    }
}
.more-option-icon {
    width: 35px;
    height: 35px;
    border-radius: 35px;
    text-align: center;
    background-color: transparent;
}
.more-option-icon:hover {
    color: var(--orange) !important;
    background-color: var(--light-gray);
}
.popoverContent {
    height: 35px;
    width: 160px;
    line-height: 35px;
    padding-left: 10px;
}
.popoverContent:hover {
    background-color: var(--off-white-light);
    cursor: pointer;
}
.email-quick-preview {
    width: 100%;
    max-width: 700px;
    min-height: 500px;
    max-height: calc(100vh - 200px);
}
</style>

<style lang="scss">
.broadcast-icon {
    fill: #9ea0a5;
}
.popoverStyle .ant-popover-inner-content {
    padding: 0;
    background-color: white;
    border-radius: 20px;
}
.tableStyle .ant-table-thead > tr > th {
    background-color: white;
}
.tableStyle .ant-table-pagination.ant-pagination {
    margin: 16px 16px;
}
.draft-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
}
.icon-link {
    background: #f5f2f2;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
